<script>
import store from '@/infrastructure/store'
import FriendCard from '@/features/friends/components/FriendCard'
import AddFriend from '@/features/friends/components/AddFriend'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    FriendCard,
    AddFriend,
  },

  setup() {
    const { isBusy: isLoading, execute: loadFriends } = useAsyncLoading({ actionFactory: () => store.dispatch('friends/fetchAllFriendRequests') })

    onMounted(loadFriends)

    return {
      isLoading,
    }
  },

  computed: {
    acceptedFriends: () => store.getters['friends/acceptedFriendRequests'],
    waitingOnActorFriendRequests: () => store.getters['friends/waitingOnActorFriendRequests'],
    waitingOnOtherPersonFriendRequests: () => store.getters['friends/waitingOnOtherPersonFriendRequests'],
    rejectedByActorFriendRequests: () => store.getters['friends/rejectedByActorFriendRequests'],
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoading">
      <v-card-title>
        <div class="mx-auto text-center">Friends</div>
      </v-card-title>
    </v-card>

    <v-card v-if="waitingOnActorFriendRequests.length > 0" class="mt-3">
      <v-expansion-panels multiple :value="[0, 1]">
        <v-expansion-panel>
          <v-expansion-panel-header class="success--text text-h5">
            You have {{ waitingOnActorFriendRequests.length }} new friend
            {{ waitingOnActorFriendRequests.length === 1 ? 'request' : 'requests' }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <FriendCard
              v-for="(friend, i) in waitingOnActorFriendRequests"
              :key="friend.friendUserId"
              :user-id="friend.friendUserId"
              :full-name="friend.friendFullName"
              :email="friend.friendEmail"
              :picture="friend.friendPicture"
              :status="friend.status"
              show-action-buttons
              :class="{ 'mb-3': i > 0 }"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <div class="mt-3">
      <FriendCard
        v-for="(friend, i) in acceptedFriends"
        :key="friend.friendUserId"
        :user-id="friend.friendUserId"
        :full-name="friend.friendFullName"
        :email="friend.friendEmail"
        :picture="friend.friendPicture"
        :status="friend.status"
        :class="{ 'mt-3': i > 0 }"
        :to="{ name: 'friend-with-items', params: { userId: friend.friendUserId } }"
      />
    </div>

    <v-card v-if="waitingOnOtherPersonFriendRequests.length > 0" class="mt-3">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Waiting for {{ waitingOnOtherPersonFriendRequests.length }} response </v-expansion-panel-header>
          <v-expansion-panel-content>
            <FriendCard
              v-for="(friend, i) in waitingOnOtherPersonFriendRequests"
              :key="friend.friendUserId"
              :user-id="friend.friendUserId"
              :full-name="friend.friendFullName"
              :email="friend.friendEmail"
              :picture="friend.friendPicture"
              :status="friend.status"
              show-action-buttons
              :class="{ 'mb-3': i > 0 }"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card v-if="rejectedByActorFriendRequests.length > 0" class="mt-3">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Rejected ({{ rejectedByActorFriendRequests.length }})</v-expansion-panel-header>
          <v-expansion-panel-content>
            <FriendCard
              v-for="(friend, i) in rejectedByActorFriendRequests"
              :key="friend.friendUserId"
              :user-id="friend.friendUserId"
              :full-name="friend.friendFullName"
              :email="friend.friendEmail"
              :picture="friend.friendPicture"
              :status="friend.status"
              show-action-buttons
              :class="{ 'mb-3': i > 0 }"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <div class="pt-3"></div>

    <AddFriend />
  </div>
</template>
