<script>
import store from '@/infrastructure/store'
import debounce from '@/infrastructure/userInput/debounce'
import FriendCard from '@/features/friends/components/FriendCard'

export default {
  components: {
    FriendCard,
  },

  data() {
    return {
      searchPhrase: null,
      debouncedSearchPhrase: null,
      setDebouncedSearchPhrase: debounce((val) => (this.debouncedSearchPhrase = val), 300),

      isLoadingCount: 0,
      results: [],
    }
  },

  watch: {
    searchPhrase(to) {
      this.setDebouncedSearchPhrase(to)
    },
    async debouncedSearchPhrase(val) {
      this.isLoadingCount++
      this.results = []
      try {
        if (val) {
          this.results = await store.dispatch('friends/searchUsersForInviting', { searchPhrase: val })
        }
      } finally {
        this.isLoadingCount--
      }
    },
  },
}
</script>

<template>
  <v-card :loading="isLoadingCount > 0">
    <v-card-text>
      <v-text-field
        label="Type to find a user to invite..."
        v-model="searchPhrase"
        :loading="isLoadingCount > 0"
        filled
        clearable
        hide-details
        @click:clear="debouncedSearchPhrase = ''"
      />

      <v-expand-transition>
        <div v-if="results && results.length > 0" class="mt-3">
          <FriendCard
            v-for="(result, i) in results"
            :key="result.profile.userId"
            :user-id="result.profile.userId"
            :full-name="result.profile.fullName"
            :picture="result.profile.picture"
            :status="result.existingFriendRequestStatus"
            :email="result.profile.email"
            show-action-buttons
            :class="{ 'mt-3': i > 0 }"
          />
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>
